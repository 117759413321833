import SHAIDataPipeline from "./../../images/shaidatapipeline.png";

import "./DataPlatform.css";

const SHAIDataPipelineImage = () => {
  return (
    <img
      className="shai-datapipeline-image"
      src={SHAIDataPipeline}
      alt="Suparna Health Data Platform - Data Pipeline"
    />
  );
};

export default SHAIDataPipelineImage;
