/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

/*
ICON SVG path parameters taken from bootstrap Person Circle 
https://icons.getbootstrap.com/icons/person-circle/

*/

import React from "react";
import { t } from "@cmsgov/design-system";
import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 512 512",
};

export default function SHHealthSystems(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon
      title={""}
      {...defaultProps}
      {...props}
      className={iconCssClasses}
      fill="Red"
    >
      <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm112 376c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-88h-96v88c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V136c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v88h96v-88c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v240z" />
    </SvgIcon>
  );
}
