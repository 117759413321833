/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

import React from "react";

import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 1200 1200",
};

export default function SHDataCalalog(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <path
        d="m1125 745.88v-4.5l-150-637.5c-1.9805-8.3906-6.7891-15.84-13.621-21.102-6.8281-5.2578-15.262-8.0039-23.879-7.7734h-675c-8.6172-0.23047-17.051 2.5156-23.879 7.7734-6.832 5.2617-11.641 12.711-13.621 21.102l-150 637.5v4.5 4.125 337.5c0 9.9453 3.9492 19.484 10.984 26.516 7.0312 7.0352 16.57 10.984 26.516 10.984h975c9.9453 0 19.484-3.9492 26.516-10.984 7.0352-7.0312 10.984-16.57 10.984-26.516v-337.5-4.125zm-832.88-595.88h615.75l132.38 562.5h-290.25c-9.9453 0-19.484 3.9492-26.516 10.984-7.0352 7.0312-10.984 16.57-10.984 26.516 0 59.25-75 75-112.5 75s-112.5-15.75-112.5-75c0-9.9453-3.9492-19.484-10.984-26.516-7.0312-7.0352-16.57-10.984-26.516-10.984h-290.25zm757.88 900h-900v-262.5h267.38c41.25 148.12 324 148.12 365.25 0h267.38zm-262.5-75c0 9.9453-3.9492 19.484-10.984 26.516-7.0312 7.0352-16.57 10.984-26.516 10.984h-300c-13.398 0-25.777-7.1484-32.477-18.75-6.6992-11.602-6.6992-25.898 0-37.5 6.6992-11.602 19.078-18.75 32.477-18.75h300c9.9453 0 19.484 3.9492 26.516 10.984 7.0352 7.0312 10.984 16.57 10.984 26.516zm-525-412.5c0-9.9453 3.9492-19.484 10.984-26.516 7.0312-7.0352 16.57-10.984 26.516-10.984h600c13.398 0 25.777 7.1484 32.477 18.75 6.6992 11.602 6.6992 25.898 0 37.5-6.6992 11.602-19.078 18.75-32.477 18.75h-600c-9.9453 0-19.484-3.9492-26.516-10.984-7.0352-7.0312-10.984-16.57-10.984-26.516zm37.5-150c0-9.9453 3.9492-19.484 10.984-26.516 7.0312-7.0352 16.57-10.984 26.516-10.984h525c13.398 0 25.777 7.1484 32.477 18.75 6.6992 11.602 6.6992 25.898 0 37.5-6.6992 11.602-19.078 18.75-32.477 18.75h-525c-9.9453 0-19.484-3.9492-26.516-10.984-7.0352-7.0312-10.984-16.57-10.984-26.516zm37.5-150c0-9.9453 3.9492-19.484 10.984-26.516 7.0312-7.0352 16.57-10.984 26.516-10.984h450c13.398 0 25.777 7.1484 32.477 18.75 6.6992 11.602 6.6992 25.898 0 37.5-6.6992 11.602-19.078 18.75-32.477 18.75h-450c-9.9453 0-19.484-3.9492-26.516-10.984-7.0352-7.0312-10.984-16.57-10.984-26.516z"
        fill="#12b0fb"
      />
    </SvgIcon>
  );
}
