

const SHNotFound = () => {
  return (
    <div className="ds-u-font-size--2xl">
      Page you are requesting is not found
    </div>
  );
};

export default SHNotFound;