/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

/*
ICON SVG path parameters taken from bootstrap Person Circle 
https://icons.getbootstrap.com/icons/person-circle/

*/

import React from "react";
import { t } from "@cmsgov/design-system";
import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 16 16",
};

export default function SHPersonCircle(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
      <path
        fill-rule="evenodd"
        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
      />
    </SvgIcon>
  );
}
