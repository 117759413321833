import SHAIMLPipeline from "./../../images/shaimlpipeline.png";

import "./DataPlatform.css";

const SHAIMLPipelineImage = () => {
  return (
    <img
      className="shai-datapipeline-image"
      src={SHAIMLPipeline}
      alt="Suparna Health Data Platform - Data Pipeline"
    />
  );
};

export default SHAIMLPipelineImage;
