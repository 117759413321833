/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

import React from "react";

import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 1200 1200",
};

export default function SHDataLabel(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <path
        d="m711.6 332.4c-16.801 16.801-25.199 37.199-25.199 60 0 45.602 39.602 85.199 85.199 85.199 45.602 0 85.199-39.602 85.199-85.199 0-45.602-39.602-85.199-85.199-85.199-22.801 0-43.203 8.4023-60 25.199zm-235.2 648-294-292.8c-9.6016-9.6016-14.398-20.398-14.398-32.398s4.8008-22.801 14.398-32.398l411.6-405.6c4.8008-4.8008 14.398-7.1992 28.801-9.6016l238.8-27.602h3.6016c9.6016 0 19.199 4.8008 27.602 13.199l82.801 79.199c8.3984 8.3984 13.199 19.199 13.199 33.602l-31.199 238.8c-2.3984 15.602-4.8008 25.199-9.6016 28.801l-408 405.6c-9.6016 9.6016-20.398 14.398-32.398 14.398-13.207 1.1992-22.805-3.6016-31.207-13.203z"
        fill="#12b0fb"
      />
    </SvgIcon>
  );
}
