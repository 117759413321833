/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

import React from "react";

import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 1200 1200",
};

export default function SHPublicHealthFrontLineWorker(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <g fill="#00449e">
        <path d="m600 360c-98.398 0-177.6 87.602-177.6 194.4 0 106.8 79.203 194.4 177.6 194.4s177.6-87.602 177.6-194.4c0-106.8-79.203-194.4-177.6-194.4zm0 346.8c-74.398 0-135.6-68.398-135.6-152.4s61.199-152.4 135.6-152.4c74.398 0 135.6 68.398 135.6 152.4 0 83.996-61.203 152.4-135.6 152.4z" />
        <path d="m558 232.8h14.398c3.6016 0 7.1992 3.6016 7.1992 7.1992v14.398c0 6 4.8008 12 12 12h19.199c6 0 12-4.8008 12-12l0.003906-14.398c0-3.6016 3.6016-7.1992 7.1992-7.1992h14.398c6 0 12-4.8008 12-12v-19.199c0-6-4.8008-12-12-12h-14.398c-3.6016 0-7.1992-3.6016-7.1992-7.1992v-14.398c0-6-4.8008-12-12-12h-19.199c-6 0-12 4.8008-12 12v14.398c0 3.6016-3.6016 7.1992-7.1992 7.1992h-14.398c-6 0-12 4.8008-12 12v19.199c-0.003906 7.1992 4.7969 12 11.996 12z" />
        <path d="m459.6 388.8 13.199-14.398c32.398-36 78-57.602 127.2-57.602 48 0 94.801 20.398 126 56.398l12 14.398 126-160.8 10.801-10.801-12-10.801c-72-67.199-165.6-104.4-264-104.4-98.398 0-192 37.199-264 104.4l-12 10.801 10.801 10.801zm140.4-256.8c85.199 0 166.8 30 231.6 86.398l-94.801 121.2c-37.199-34.801-86.398-54-136.8-54-51.602 0-100.8 19.199-138 54l-93.602-121.2c64.801-55.199 146.4-86.398 231.6-86.398z" />
        <path d="m924 1047.6c-6-34.801-13.199-64.801-21.602-88.801-44.398-121.2-182.4-164.4-297.6-164.4h-10.801c-114 0-253.2 43.199-297.6 164.4-8.3984 24-16.801 54-21.602 88.801l-2.3984 16.801 16.801 6c82.801 28.801 176.4 28.801 276 27.602h70.801 33.602c86.398 0 168-2.3984 241.2-27.602l16.801-6zm-289.2 9.5977h-34.801-34.801c-88.801 0-172.8 1.1992-244.8-20.398 4.8008-24 10.801-45.602 16.801-63.602 37.199-100.8 157.2-136.8 258-136.8h10.801c100.8 0 220.8 36 258 136.8 6 18 12 38.398 16.801 63.602-73.199 21.598-157.2 20.398-246 20.398z" />
      </g>
    </SvgIcon>
  );
}
