/*

  copyright 2023 - Present SUPARNA HEALTH AI LLC

*/

import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useClickOutsideHandler from "../../platform/utilities/useSHAIClickOutsideHandler";

import SHSecondaryMegaMenu from "./SHSecodaryMegaMenu";
import "./SHMenu.css";

const SHPrimaryMenu = () => {
  const [openSecondaryMenu, setOpenSecondaryMenu] = useState<Boolean>(false);
  const megaMenuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  function handlePrimaryMenuClick() {
    setOpenSecondaryMenu(!openSecondaryMenu);
    console.log("Primary Menu Clicked ");
  }

  function closeMegaMenu() {
    console.log("Outside Handler Called");
    setOpenSecondaryMenu(false);
  }

  const SHNavLinkHandler = (navPath: string) => {
    navigate(navPath);
  };

  useClickOutsideHandler(megaMenuRef, closeMegaMenu);

  return (
    <nav className="ds-u-margin-left--7 ds-u-margin-right--7">
      <ul
        id="shai-header-primary-menu"
        className="ds-u-md-margin-left--7 ds-u-md-margin-right--7 ds-u-md-display--flex ds-u-md-flex-direction--row ds-u-sm-display-none ds-u-color--white ds-u-md-justify-content--between ds-u-md-font-size--lg shai-primary-menu-items ds-u-sm-display--none"
      >
        <li
          className="ds-u-padding--1"
          onClick={() => SHNavLinkHandler("/shaiplatform")}
        >
          Health AI Data Platform{" "}
        </li>

        <li
          className="ds-u-padding--1"
          onClick={() => SHNavLinkHandler("/healthsystems")}
        >
          Health Systems
        </li>
        <li
          className="ds-u-padding--1"
          onClick={() => SHNavLinkHandler("/publichealth")}
        >
          Public Health
        </li>
      </ul>
    </nav>
  );
};

export default SHPrimaryMenu;
