/*

Copyright 2024 - Current 
SUPARNA HEALTH AI LLC

*/

import { Link } from "react-router-dom";

import { ThirdPartyExternalLink } from "@cmsgov/design-system";

import SHPublicHeader from "../../components/header/SHPublicHeader";

import SHAIPublicHealthMainImage from "./SHAIPublicHealthMainImage";

import SHRecordLinkage from "../../components/icons/SHRecordLinkage";
import SHHealthEquity from "../../components/icons/SHHealthEquity";

import SHPublicHealthFrontLineWorker from "../../components/icons/SHPublicHealthFrontLineWorker";
import SH360View from "../../components/icons/SH360View";
import SHChat from "../../components/icons/SHChat";
import SHDiseaseOutbreak from "../../components/icons/SHDiseaseOutbreak";
import SHWarning from "../../components/icons/SHWarning";
import SHDiseaseClusters from "../../components/icons/SHDiseaseClusters";
import SHDiseasePathogen from "../../components/icons/SHDiseasePathogen";
import SHMultipleHumanContacts from "../../components/icons/SHMultipleHumanContacts";
import SHStoppingTransmission from "../../components/icons/SHStoppingTransmission";

import SHPandemic from "../../components/icons/SHPandemic";

import "./PublicHealth.css";
import SHGenerativeAI from "../../components/icons/SHGenerativeAI";

const SHPublicHealthMainPage = () => {
  return (
    <>
      <SHPublicHeader />
      <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-md-flex-direction--row ds-u-justify-content--center">
        <SHAIPublicHealthMainImage />
      </div>
      <div className="ds-u-fill--primary-lightest ds-u-padding-top--1 ds-u-padding-left--1 ds-u-padding-right--1 ds-u-md-padding-top--4 ds-u-md-padding-left--6 ds-u-md-padding-right--6 ">
        <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-padding--2 ds-u-fill--white">
          <div>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              <span className="ds-u-font-weight--bold">
                {" "}
                Suparna Health AI Public Health Offerings{" "}
              </span>
              are built on top of
              <Link className="ds-c-link" to="/shaiplatform">
                {" "}
                Suparna Health AI Data Platform
              </Link>
              , which is an end to end platform from data ingestion to processed
              meaningful tasks and reports. These are aligned along the vision
              of &nbsp;
              <ThirdPartyExternalLink
                href="https://www.cdc.gov/pcd/issues/2017/17_0017.htm"
                origin="SUPARNA HEALTH AI LLC Website"
              >
                Public Health 3.0
              </ThirdPartyExternalLink>
            </p>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              Artificial Intelligence and Machine Learning (AI/ML) forms the
              core of the Suparna Health AI Data Platform and its Public Health
              specific offerings. Entire platform and its offerings are
              architected, designed and developed with focus on using machine
              learning (ML) to improve contextual intelligence and health equity
              along tasks and activities of public health. Special efforts has
              been taken to keep Public Health Officials at Top of the AI Loop
              (Humans at the Top of AI Loop).
            </p>
          </div>
        </div>
      </div>

      {/* Main Block End */}

      <div className="ds-u-fill--primary-lightest ds-u-padding-top--1  ds-u-md-padding-top--1   ds-u-padding-bottom--1 ds-u-md-padding-top--1">
        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHRecordLinkage className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <SHHealthEquity className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Local Health Departments Disease Datasets, Record Linkage, SoDH,
              Health Equity{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              Suparna Health AI Data platform data pipeline process disease
              (chronic & infectious ) area-specific datasets of (Cardiovascular
              diseases, Hepatitis, Hyperlipidemia, HIV/AIDS, Influenza,
              COVID-19, etc), datasets from federal, state, and local level
              surveys, electronic case reporting records (eCR), healthcare
              capacity, hospital-acquired infections data, demographics,
              Medicare/Medicaid beneficiaries, social determinants of health,
              water & sanitation, syndromic surveillance, health exchanges,
              immunization registries, cancer registries, bulk FHIR data from
              health systems, human services data.  Platform inbuilt data
              ingestion connectors for multiple file formats (FHIR, JSON,
              GeoJSON, CSV, XLS, Parquet, SFTP) allow connection to many other
              data streams specific to public health departments. 
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHPublicHealthFrontLineWorker className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <SH360View className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              360 View, Actionable Intelligence for Frontline Public Health
              Practioner{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              These pipelines also map these processed datasets to the ZIP
              Code/Sub-county level. This helps bring a 360-degree view
              to Frontline Public Health Practitioners in his/her jurisdictional
              area. Platform provides a set of Mobile Applications for both
              iPhone and Android devices. On his/her web or mobile applications,
              Frontline Public Health Practitioner will have complete
              situational awareness - current ongoing disease-wise infections,
              cases at different steps of case management workflow, healthcare
              systems capacity, demographics profile, medicare/Medicaid
              beneficiaries, food banks, SDoH and health equity parameters,
              current ongoing outbreaks in its own and nearby jurisdictions. 
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHChat className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <SHGenerativeAI className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Generative AI, Retrieval-augmentation-generation (RAG), Summary &
              AI Chat{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              Retrieval-augmentation-generation (RAG) based Generative AI (LLMs)
              applications will provide chat-like queries of situational
              awareness and contextual search. Although these web and mobile
              applications have many intelligent features built-in, it has
              manual overrides and allows all traditional public health tasks to
              be performed like submitting online confidential morbidity reports
              (CMR) reports by public health practitioners. 
            </p>
            <p className="ds-u-font-size--lg">
              GenAI functionalities of summarization, question & answering are
              exposed through REST APIs. The platform provides chat-like
              interfaces on Mobile and Web which are also integrated into
              platform and outside data streams.
            </p>
            <p className="ds-u-font-size--lg">
              The platform has AI search and its GenerativeAI module has
              guardrails built into it to minimize hallucinations. 
            </p>
            <p className="ds-u-font-size--lg">
              The combination of these technologies along with web and mobile
              applications helps public health officials to perform many of
              their day-to-day tasks including filling out forms, and a simple
              chat-like interaction.
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDiseaseOutbreak className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <SHWarning className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Outbreak Detection/Early Warning{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              Natural Language Processing (NLP) processes social media posts,
              news posted on the internet, and Google search results for names
              and symptoms (signals) for infectious diseases. ML Algorithms
              parse, filters, classifies and aggregates them geography-wise to
              the ZIP Code level. 
            </p>
            <p className="ds-u-font-size--lg">
              On real real-time basis/hourly/daily this data is processed
              together with electronic case reporting, syndromic surveillance &
              wastewater surveillance-like datasets. 
            </p>
            <p className="ds-u-font-size--lg">
              A rule-based expert system processes this for the threshold of
              outbreaks and produces a score of probable disease outbreaks.
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDiseaseClusters className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Disease Clusters{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              ML clustering algorithms create a heat map of disease layered on a
              GIS Map. The platform has an in-built AI search-based GIS module
              that seamlessly integrates with an output of ML clustering. 
            </p>
            <p className="ds-u-font-size--lg">
              The platform also shows the contacts heat map on the GIS
              application. GIS application has features for querying the layers
              and seeing super-imposed maps of disease sub-layers and social
              determinants of health. 
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDiseasePathogen className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Disease/Pathogen Identification{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform has an in-built recommender system for
              disease/pathogen classification.
            </p>
            <p className="ds-u-font-size--lg">
              In situations of diseases sharing similar symptoms and routes of
              transmission, the recommender system generates a candidate list
              for molecular testing. AI/ML also helps in identifying
              laboratories best suited for testing. 
            </p>
            <p className="ds-u-font-size--lg">
              The module is flexible and allows it to add additional
              disease-specific ML modules and applications like disease-specific
              CNNs.
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHMultipleHumanContacts className="ds-u-font-size--4xl ds-c-icon-color--primary" />

            <SHStoppingTransmission className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Contact Tracing/Stopping Transmission{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform has built-in contact tracing functionality to be used
              by public health contact tracers. The application shits through
              case management data to find super-spreaders. These
              super-spreaders will be scored and ranked, so that contact tracing
              can be prioritized.
            </p>
            <p className="ds-u-font-size--lg">
              Depending on the racial and ethnic composition of the area,
              disease prevalence, and demographic risk factors, the platform
              will suggest the demographically diverse set of contact tracers
              (their proportion). It will also take into account language
              diversity like English and Spanish and suggest an appropriate mix
              of contact tracers. 
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHPandemic className="ds-u-font-size--4xl ds-c-icon-color--primary" />

            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Fast Moving Infectious Disease Outbreaks /Pandemics{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              In widespread infections like scenarios where non-pharmaceutical
              interventions are required but are in limited capacity and numbers
              (tests/vaccines etc), the system has support for reinforcement
              learning (RL). This will allow the platform to create a
              risk-stratified population and suggest the allocation of resources
              as per the risk category of a particular individual. As the risk
              profile of people will continuously change, RL will constantly
              update the risk profiles and suggest optimized resource
              allocations. Risk assessment will happen continuously, several
              times a day potentially. 
            </p>
          </div>
        </div>
      </div>

      <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-md-flex-direction--row ds-u-margin-top--2 ds-u-fill--background-inverse">
        <div className="shai-ds-u-footer-section1 ds-u-padding--2">
          {" "}
          <span className="ds-u-font-size--xl ds-u-font-weight--bold ds-u-color--white ds-u-margin--6 ds-u-padding-top--4">
            {" "}
            SUPARNA HEALTH AI LLC
          </span>
          <br />
          <span className="ds-u-font-size--lg ds-u-color--white ds-u-margin--6">
            201 Spear Street, Suite 1100
          </span>
          <br />
          <span className="ds-u-font-size--lg ds-u-color--white ds-u-margin--6">
            San Francisco, CA 94015
          </span>
          <br />
          <span className="ds-u-font-size--xl ds-u-color--white ds-u-margin--6">
            www.s11h.ai
          </span>
        </div>
        <div className="shai-ds-u-footer-section2 ds-u-font-size--xl ds-u-margin-left--6 ds-u-color--gray-lightest">
          <p>Contact for Partnerships </p>
          <p>partners@s11h.ai</p>
        </div>
        <div className="shai-ds-u-footer-section2 ds-u-font-size--xl ds-u-margin-left--6 ds-u-color--gray-lightest">
          <p>Contact for Sales Enquiries </p>
          <p>sales@s11h.ai</p>
        </div>
      </div>

      {/* Data Pipeline  */}
    </>
  );
};
export default SHPublicHealthMainPage;
