/*

Copyright 2023 - Current SUPARNA HEALTH AI LLC

*/

import { Button } from "@cmsgov/design-system";
import "./SHI18nButton.css";

interface SHI18nButtonProps {
  label: string;
  children?: React.ReactNode;
  className?: string;
}

const SHIconButtonLink = ({ label, children }: SHI18nButtonProps) => {
  return (
    <Button
      onDark={true}
      variation={"ghost"}
      className="ds-u-padding--0 ds-u-margin--0 ds-u-padding-left--1 ds-u-padding-right--1"
    >
      {children}
      <span className="ds-u-md-margin-left--1 shai-display-on-mobile ">
        {label}
      </span>
    </Button>
  );
};
export default SHIconButtonLink;
