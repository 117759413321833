/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

import React from "react";

import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 1200 1200",
};

export default function SHThreeWaySplit(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <path
        d="m705.6 389.4 84.602 84.602-130.2 130.2v-304.2h120l-180-180-180 180h120v304.2l-130.2-130.2 84.602-84.602h-254.4v254.4l84.602-84.602 127.2 127.2c63 63 88.199 132 88.199 243 0 30.602 0 30.602 30.602 30.602h58.801c30.602 0 30.602 0 30.602-30.602 0-111 25.199-180 88.199-243l127.2-127.2 84.602 84.602v-254.4z"
        fill="#12b0fb"
      />
    </SvgIcon>
  );
}
