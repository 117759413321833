/*

Copyright 2023 - Present SUPARNA HEALTH AI LLC


*/
import { useNavigate } from "react-router-dom";

import { Button } from "@cmsgov/design-system";

import SHPublicHeader from "../components/header/SHPublicHeader";

import "./SHPage.css";
import SHHealthSystems from "../components/icons/SHHealthSystems";
import SHPublicHealth from "../components/icons/SHPublicHealth";

import SHComputerMobileTab from "../components/icons/SHComputerMobileTab";

import SHAIDataPlatformMainImage from "./aidataplatform/SHAIDataPlatformMainImage";

const SHMainPage = () => {
  const navigate = useNavigate();
  const SHNavLinkHandler = (navPath: string) => {
    navigate(navPath);
  };

  return (
    <>
      <SHPublicHeader />

      <SHAIDataPlatformMainImage />

      <div className="ds-u-fill--primary-lightest ds-u-padding-top--1 ds-u-padding-left--1 ds-u-padding-right--1 ds-u-md-padding-top--4 ds-u-md-padding-left--6 ds-u-md-padding-right--6 ">
        <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-padding--2 ds-u-fill--white">
          <div>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              <span className="ds-u-font-weight--bold">
                {" "}
                SUPARNA HEALTH AI DATA PLATFORM{" "}
              </span>
              is an integrated suite of Data Catalog, Data integration layers,
              Data & Machine Learning (ML) pipelines, and applications to
              orchestrate, monitor & manage pipelines and Generative AI
              applications.
            </p>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              Integration layers have been developed to make the platform
              suitable to deploy on major cloud offerings like Microsoft Azure,
              Amazon AWS, and Google Cloud. Platform can also be deployed on
              bare metal servers running Kubernetes.
            </p>

            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              Applications and layers serving domain specific use cases for
              Public Health and Health Systems are built around REST APIs
              exposed by platform.
            </p>
          </div>
          <div className="ds-u-display--flex ds-u-margin-y--2 ds-u-flex-direction--row ds-u-justify-content--center">
            <Button
              className="ds-u-font-size--lg ds-u-margin-top--2"
              onClick={() => SHNavLinkHandler("/shaiplatform")}
            >
              {" "}
              Click for more details about SUPARNA HEALTH AI DATA PLATFORM{" "}
            </Button>
          </div>
        </div>
      </div>
      <div className="ds-u-fill--primary-lightest">
        <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-md-flex-direction--row  ds-u-justify-content--between">
          <div className="ds-u-display-flex ds-u-flex-direction--column ds-u-margin-x--2 ds-u-md-margin-left--7 ds-u-md-margin-right--0 ds-u-margin-top--2 ds-u-md-margin-top--4 ds-u-fill--white shai-ds-u-card">
            <header className=" ds-u-margin--2  shai-ds-u-card__heading">
              <SHHealthSystems className="ds-u-font-size--2xl ds-c-icon-color--primary" />
              <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2 ds-u-valign--middle">
                {" "}
                Solutions for Health Systems{" "}
              </span>
            </header>
            <div className="shai-ds-u-card__content ds-u-padding-x--2">
              <p className="ds-u-font-size--lg">
                Platform provides diseases specific DIGITAL TWIN for health
                systems. These digital twins can be queried using LLM/Generative
                AI layer by clinicians at point of care. Platform uses AI to
                create disease specific registries and scoring models.
              </p>
            </div>
            <div className="ds-u-margin-y--2 ds-u-display--flex ds-u-justify-content--center">
              <Button
                className="ds-u-font-size--lg ds-u-margin-top--2"
                onClick={() => SHNavLinkHandler("/healthsystems")}
              >
                {" "}
                Click for Health System Solutions{" "}
              </Button>
            </div>
          </div>

          <div className="ds-u-display-flex  ds-u-flex-direction--column ds-u-margin-top--4 ds-u-margin-x--2 ds-u-md-margin-x--4 ds-u-fill--white   shai-ds-u-card">
            <header className=" ds-u-margin--2  shai-ds-u-card__heading">
              <SHPublicHealth className="ds-u-font-size--2xl ds-c-icon-color--primary" />
              <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2 ds-u-valign--middle">
                {" "}
                Solutions for Public Health{" "}
              </span>
            </header>
            <div className="shai-ds-u-card__content ds-u-padding-x--2">
              <p className="ds-u-font-size--lg">
                Public Health Problems are local and their solutions are also
                local. Platform creates a DIGITAL TWIN for local health
                department. The digital twin can be queried for
                notifiable/chronic diseases, outbreaks, health equity and needed
                public health interventions at ZIP code level. It support Bulk
                FHIR for integration with health systems.
              </p>
            </div>
            <div className="ds-u-margin-y--2 ds-u-display--flex ds-u-justify-content--center">
              <Button
                className="ds-u-font-size--lg ds-u-margin-top--2"
                onClick={() => SHNavLinkHandler("/publichealth")}
              >
                {" "}
                Click for Public health Solutions{" "}
              </Button>
            </div>
          </div>
        </div>

        {/* Application Block */}

        <div className="ds-u-margin-x--1 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHComputerMobileTab className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Web, Mobile & GIS Apps{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              Health AI Data Platform has many in-built end user software
              applications like Dashboard, Patient Journey Views, Search, Q&A
              Systems, Timelines Views for different stakeholders. Platform has
              Mobile Applications for Q&A on Digital Twin, Chat between
              healthcare staff and Curated reports. GIS Layer helps to see
              report & data in many layers and zoom levels
            </p>
          </div>
        </div>

        {/* Footer Section */}
        <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-md-flex-direction--row ds-u-margin-top--2 ds-u-fill--background-inverse">
          <div className="shai-ds-u-footer-section1 ds-u-padding--2">
            {" "}
            <span className="ds-u-font-size--xl ds-u-font-weight--bold ds-u-color--white ds-u-margin--6 ds-u-padding-top--4">
              {" "}
              SUPARNA HEALTH AI LLC
            </span>
            <br />
            <span className="ds-u-font-size--lg ds-u-color--white ds-u-margin--6">
              201 Spear Street, Suite 1100
            </span>
            <br />
            <span className="ds-u-font-size--lg ds-u-color--white ds-u-margin--6">
              San Francisco, CA 94015
            </span>
            <br />
            <span className="ds-u-font-size--xl ds-u-color--white ds-u-margin--6">
              www.s11h.ai
            </span>
          </div>
          <div className="shai-ds-u-footer-section2 ds-u-font-size--xl ds-u-margin-left--6 ds-u-color--gray-lightest">
            <p>Contact for Partnerships </p>
            <p>partners@s11h.ai</p>
          </div>
          <div className="shai-ds-u-footer-section2 ds-u-font-size--xl ds-u-margin-left--6 ds-u-color--gray-lightest">
            <p>Contact for Sales Enquiries </p>
            <p>sales@s11h.ai</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SHMainPage;
