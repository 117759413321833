/*

Copyright 2023 - current SUPARNA HEALTH AI LLC

*/

import SHAIDataPlatform from "./../../images/shaidataplatform.png";

import "./DataPlatform.css";

const SHAIDataPlatformMainImage = () => {
  return (
    <img
      className="shai-dataplatform-image"
      src={SHAIDataPlatform}
      alt="Suparna Health Data Platform - Data and ML Pipeline"
    />
  );
};

export default SHAIDataPlatformMainImage;
