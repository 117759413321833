/*

Copyright 2023 - current SUPARNA HEALTH AI LLC

*/

import SHAILogo from "./../../images/shailogowhite.png";

import "./Header.css";

const SHAILogoImage = () => {
  return (
    <img
      className="shai-logo-image"
      src={SHAILogo}
      alt="Suparna Health AI Logo"
    />
  );
};

export default SHAILogoImage;
