/*

Copyright 2023 - Present SUPARNA HEALTH AI LLC

*/

import SHPublicHeader from "../../components/header/SHPublicHeader";

import SHAIDataPipelineImage from "./SHAIDataPipelineImage";
import SHAIMLPipelineImage from "./SHAIMLPipelineImage";

import SHCloudStorage from "../../components/icons/SHCloudStorage";
import SHDataCalalog from "../../components/icons/SHDataCalalog";
import SHDataIngestion from "../../components/icons/SHDataIngestion";
import SHDataCleaning from "../../components/icons/SHDataCleaning";
import SHDataValidation from "../../components/icons/SHDataValidation";
import SHDataTranformation from "../../components/icons/SHDataTranformation";
import SHDataLabel from "../../components/icons/SHDataLabel";
import SHThreeWaySplit from "../../components/icons/SHThreeWaySplit";
import SHNeuralNetwork from "../../components/icons/SHNeuralNetwork";
import SHMonitoring from "../../components/icons/SHMonitoring";
import SHGenerativeAI from "../../components/icons/SHGenerativeAI";

const SHAIHealthPlatformMainPage = () => {
  return (
    <>
      <SHPublicHeader />

      <div className="ds-u-fill--primary-lightest ds-u-padding-top--1 ds-u-padding-bottom--1 ds-u-padding-left--1 ds-u-padding-right--1 ds-u-md-padding-top--4 ds-u-md-padding-left--6 ds-u-md-padding-right--6 ds-u-md-padding-bottom--2">
        <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-padding--2 ds-u-fill--white">
          <div>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              <span className="ds-u-font-weight--bold">
                {" "}
                SUPARNA HEALTH AI DATA PLATFORM{" "}
              </span>
              is an integrated suite of Data Catalog, Data integration layers,
              Data & Machine Learning (ML) pipelines, and applications to
              orchestrate, monitor & manage pipelines and Generative AI
              applications.
            </p>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              Integration layers have been developed to make the platform
              suitable to deploy on major cloud offerings like Microsoft Azure,
              Amazon AWS, and Google Cloud. Platform can also be deployed on
              bare metal servers running Kubernetes.
            </p>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              Applications and layers serving domain specific use cases for
              Public Health and Health Systems are built around REST APIs
              exposed by platform.
            </p>
            <p className="ds-u-font-size--xl ds-u-padding-left--4">
              The platform has a default set of user-facing Web and Mobile
              Applications for Dashboards, Chats, and Reports available on
              Mobile and Web Applications. Domain-specific offerings have their
              use case-specific applications. During the solutions
              implementation phase, additional applications can be developed and
              deployed. The platform also supports REST API for data to
              third-party applications.
            </p>
          </div>
        </div>
      </div>
      <div className="ds-u-fill--primary-lightest ds-u-padding-top--1  ds-u-md-padding-top--1   ds-u-padding-bottom--1 ds-u-md-padding-top--1">
        {/* Data Pipeline */}

        <div className="ds-u-fill--white ds-u-margin-left--1 ds-u-margin-right--1 ds-u-margin-top--1 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-md-margin-top--4">
          <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-md-flex-direction--row">
            <div className="shai-flex-two-parts-size">
              <SHAIDataPipelineImage />
            </div>
            <div className="shai-flex-two-parts-size ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-margin-left--1 ds-u-margin-right--1 ds-u-margin-top--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-md-margin-top--4 ">
              <p className="ds-u-font-size--xl">
                <span className="ds-u-font-weight--bold"> DATA PIPELINE </span>
                is implemented on top of Apache Airflow. The platform has
                implemented its UI layer for managing and controlling pipelines.
                These implemented UI layers help to implement organization data
                governance policies. This also provides orchestration,
                scheduling, lineage, and run-specific details of the pipeline.
                For privileged users, the platform exposes Apache Airflow's
                default UI.
              </p>
            </div>
          </div>
        </div>

        {/* ML Pipeline */}

        <div className="ds-u-fill--white ds-u-margin-left--1 ds-u-margin-right--1 ds-u-margin-top--1 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-md-margin-top--4">
          <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-md-flex-direction--row">
            <div className="shai-flex-two-parts-size">
              <SHAIMLPipelineImage />
            </div>
            <div className="shai-flex-two-parts-size ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-margin-left--1 ds-u-margin-right--1 ds-u-margin-top--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-md-margin-top--4 ">
              <p className="ds-u-font-size--xl">
                <span className="ds-u-font-weight--bold">
                  {" "}
                  MACHINE LEARNING (ML) PIPELINE{" "}
                </span>
                has been built on top of MLFlow. The platform implements its UI,
                which provides it with much more exploration of the ML pipeline
                with additional details of pipeline runs and data lineage,
                explainability, and fairness. For a selected set of users, the
                platform provides MLFlow default UI.
              </p>
            </div>
          </div>
        </div>

        {/* Generative AI  */}
        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHGenerativeAI className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Generative AI{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              LangChain is our orchestration layer for Generative AI (LLM)
              applications. Platform has support for Llama2, OpenAI, BERT, or
              any other LLM providing access through REST APIs. Embeddings are
              stored as well as vector searched in Milvus.
            </p>
            <p className="ds-u-font-size--lg">
              GenAI functionalities of summarization, question & answering are
              exposed through REST APIs. The platform provides chat-like
              interfaces on Mobile and Web which are also integrated into
              platform and outside data streams.
            </p>
          </div>
        </div>

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHCloudStorage className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Cloud Data Storage{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              Platform supports storage of Raw, semi-processed and processed
              datasets on either Amazon S3 Data Storage or Azure Blob storage.
              For API serving, datasets are stored in MongoDB Atlas or Postgres
              DB. Vector data storage for Generative AI applications are done in
              Milvus.
            </p>
          </div>
        </div>

        {/*  Data Catalog */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDataCalalog className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Data Catalog{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The Data Catalog module helps implement Data Governance as per the
              customer organization policy. The module also implements data
              lineage as data moves from one stage of the pipeline to another
              state of the pipeline. Users can explore and query available
              datasets (both ingested raw and processed) and see the schema
              details, ownership, lineage, and statistical properties.
            </p>
          </div>
        </div>

        {/* Data Ingestion */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDataIngestion className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Data Ingestion{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The data Ingestion module allows the ingestion of outside data. By
              default, the platform has in-built connectors for JSON, TXT, CSV,
              SFTP, ZIP, SQL, REST API, and Graph QL. Domain-specific offerings
              like Health Systems and Public Health provide additional
              connections like FHIR, HL7, etc.
            </p>
          </div>
        </div>

        {/* Data Cleaning */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDataCleaning className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Data Cleaning{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform comes with a set of UI to help explore and find
              anomalies in incoming data and has features to correct them. These
              default capabilities can be extended through in-built support for
              using the Pandas library or PySpark for data cleaning. This module
              also implements the De-Identification of data.
            </p>
          </div>
        </div>

        {/* Data Validation */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDataValidation className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Data Validation{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform has a UI to help define the schema of data for
              validation. These default capabilities can be extended through
              custom functions or the open source Great Expectations library.
            </p>
          </div>
        </div>

        {/* Data Linkage and Transformations */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDataTranformation className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Data Linkage & Transformation{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform has support for selecting the datasets and provides a
              few instructions with field-level details in records for linkage.
              The default linkage generated through platforms has UIs to further
              refine the linkage. These instructions are stored and processed in
              each run of the pipeline. The output of the transformation goes
              into the feature store and this is the start point of machine
              learning (ML) pipelines.
            </p>
          </div>
        </div>

        {/* Data labeling  */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHDataLabel className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Data Labeling{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              These are a few instances where manual data labeling is required.
              This module provides the UI for data labeling and label
              corrections. It has a workflow built for label creation and
              verification. Domain-specific offerings implement additional
              features like for Health Systems and Public Health labeling of
              diseases using ICD-10-CM for their Natural Language Processing
              (NLP) modules.
            </p>
          </div>
        </div>

        {/* Three way split    */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHThreeWaySplit className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              Dataset Splitting{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform has a default split of 80% for training, 10% for
              validation, and 10% for testing. Using platform UI, users can
              define the new ratios of the train, validate & test splits.
            </p>
          </div>
        </div>

        {/* ML Models Training */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHNeuralNetwork className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              ML Models Training{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform has in-built ML algorithms (supervised learning) of
              Classifications, Regression, Decision Trees, Random Forest,
              XGBoost, and Neural Networks. NLP-related algorithms of Named
              Entity Recognition (NER), Text Classification, and open-source
              BERT models are implemented. Domain-specific training for public
              health and health systems is done using synthetic data. These
              algorithms can be fine-tuned and additional algorithms can be
              added using sciktit-learn, PyTorch, spaCy, and BERT modules.
              Experimentation details in ML Pipeline UI help in model selection
              and promotion of the model.
            </p>
          </div>
        </div>

        {/* ML Monitoring */}

        <div className="ds-u-padding-bottom--4 ds-u-md-padding-bottom--4 ds-u-md-margin-left--7 ds-u-md-margin-right--4 ds-u-margin-top--4 ds-u-fill--white">
          <header className=" ds-u-margin--2 ds-u-padding-top--2 shai-ds-u-card__heading">
            <SHMonitoring className="ds-u-font-size--4xl ds-c-icon-color--primary" />
            <span className="ds-u-font-size--2xl ds-u-font-weight--bold ds-u-color--primary-darkest  ds-u-margin-left--2  ds-u-valign--middle">
              {" "}
              ML Models Monitoring{" "}
            </span>
          </header>
          <div className="shai-ds-u-card__content ds-u-padding-x--2">
            <p className="ds-u-font-size--lg">
              The platform monitors model performance, input data drift, and
              biases on a continuous basis.
            </p>
          </div>
        </div>

        {/* Footer Section */}
        <div className="ds-u-display--flex ds-u-flex-direction--column ds-u-md-flex-direction--row ds-u-margin-top--2 ds-u-fill--background-inverse">
          <div className="shai-ds-u-footer-section1 ds-u-padding--2">
            {" "}
            <span className="ds-u-font-size--xl ds-u-font-weight--bold ds-u-color--white ds-u-margin--6 ds-u-padding-top--4">
              {" "}
              SUPARNA HEALTH AI LLC
            </span>
            <br />
            <span className="ds-u-font-size--lg ds-u-color--white ds-u-margin--6">
              201 Spear Street, Suite 1100
            </span>
            <br />
            <span className="ds-u-font-size--lg ds-u-color--white ds-u-margin--6">
              San Francisco, CA 94015
            </span>
            <br />
            <span className="ds-u-font-size--xl ds-u-color--white ds-u-margin--6">
              www.s11h.ai
            </span>
          </div>
          <div className="shai-ds-u-footer-section2 ds-u-font-size--xl ds-u-margin-left--6 ds-u-color--gray-lightest">
            <p>Contact for Partnerships </p>
            <p>partners@s11h.ai</p>
          </div>
          <div className="shai-ds-u-footer-section2 ds-u-font-size--xl ds-u-margin-left--6 ds-u-color--gray-lightest">
            <p>Contact for Sales Enquiries </p>
            <p>sales@s11h.ai</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SHAIHealthPlatformMainPage;
