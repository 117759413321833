import React from "react";
import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 100 100",
};

export default function SHComputerMobileTab(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <path d="m77.551 52.441v-22.121c0-2.6094-1.0352-5.1133-2.8828-6.957-1.8438-1.8477-4.3477-2.8828-6.957-2.8828h-37.391c-5.4336 0-9.8398 4.4062-9.8398 9.8398v27.551c0 2.6094 1.0352 5.1133 2.8828 6.957 1.8438 1.8477 4.3477 2.8828 6.957 2.8828h9.6445c-0.9375 4.582-4.9688 7.875-9.6445 7.8711-1.0859 0-1.9688 0.88281-1.9688 1.9688 0 1.0898 0.88281 1.9688 1.9688 1.9688h45.5c1.0117-0.03125 1.9727-0.46094 2.6641-1.1992 0.69531-0.73828 1.0664-1.7227 1.0352-2.7383v-19.68c0.019531-1.4258-0.73438-2.75-1.9688-3.4609zm-47.23 11.336c-2.4961-0.007813-4.7188-1.5859-5.5508-3.9375h21.293v3.9375zm15.742 11.809-6.1211-0.003907c2.1406-2.1211 3.5234-4.8867 3.9375-7.8711h2.1836v5.4141c0 0.83984 0.15234 1.6719 0.45312 2.457zm0-29.027v9.3438h-21.648v-25.582c0-3.2617 2.6445-5.9062 5.9062-5.9062h37.391c3.2617 0 5.9062 2.6445 5.9062 5.9062v10.234c-0.62891-0.25391-1.293-0.38672-1.9688-0.39453h-19.68c-3.3906 0.14844-6.0273 3.0039-5.9062 6.3945zm15.746 29.023h-9.8398c-1.2148-0.15234-2.0859-1.2422-1.9688-2.457v-1.4766h11.809zm0-19.68v11.809h-11.809v-21.156c-0.11719-1.2148 0.75391-2.3047 1.9688-2.457h19.68c1.2109 0.15234 2.082 1.2422 1.9688 2.457v5.4141h-8.1094c-1.0117 0.03125-1.9727 0.46094-2.6641 1.1992-0.69531 0.73828-1.0664 1.7227-1.0352 2.7344zm13.777 19.68h-9.8438v-3.9336h9.8398zm0-7.8711h-9.8438v-11.809h9.8398z" />
      <path d="m37.621 42.699c-0.37109-0.375-0.87109-0.58203-1.3984-0.58203-0.52344 0-1.0273 0.20703-1.3945 0.58203l-4.5078 4.5273v-3.1289c0-1.0898-0.88281-1.9688-1.9688-1.9688-1.0859 0-1.9688 0.87891-1.9688 1.9688v3.9375-0.003906c0 3.2617 2.6445 5.9062 5.9062 5.9062h3.9336c1.0898 0 1.9688-0.88281 1.9688-1.9688s-0.87891-1.9688-1.9688-1.9688h-3.1289l4.5273-4.5078c0.37109-0.36719 0.58203-0.87109 0.58203-1.3945 0-0.52734-0.21094-1.0312-0.58203-1.3984z" />
      <path d="m53.938 36.223v-3.9336c0-3.2617-2.6445-5.9062-5.9062-5.9062h-3.9336c-1.0898 0-1.9688 0.88281-1.9688 1.9688 0 1.0859 0.87891 1.9688 1.9688 1.9688h3.1289l-4.5273 4.5078c-0.375 0.36719-0.58203 0.87109-0.58203 1.3945 0 0.52734 0.20703 1.0273 0.58203 1.3984 0.36719 0.37109 0.87109 0.58203 1.3984 0.58203 0.52344 0 1.0273-0.21094 1.3945-0.58203l4.5078-4.5273v3.1289c0 1.0898 0.88281 1.9688 1.9688 1.9688s1.9688-0.87891 1.9688-1.9688z" />
    </SvgIcon>
  );
}
