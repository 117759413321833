/*

Copyright 2021-2022 SATISH KUMAR
          2022 - Present SUPARNA HEALTH AI LLC

*/

/*

Top Level - Application Level Route Setup is done here

*/

import React from "react";

import { Routes, Route } from "react-router-dom";

import SHMainPage from "./pages/SHMainPage";
import SHNotFound from "./pages/SHNotFound";

import SHHealthSystemsMainPage from "./pages/healthsystems/SHHealthSystemsMainPage";
import SHPublicHealthMainPage from "./pages/publichealth/SHPublicHealthMainPage";
import SHAIHealthPlatformMainPage from "./pages/aidataplatform/SHAIHealthPlatformMainPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<SHMainPage />}>
        <Route index element={<SHMainPage />} />
      </Route>
      <Route path="healthsystems" element={<SHAIHealthPlatformMainPage />} />
      <Route path="publichealth" element={<SHPublicHealthMainPage />} />
      <Route path="shaiplatform" element={<SHAIHealthPlatformMainPage />} />
      <Route path="*" element={<SHNotFound />} />
    </Routes>
  );
}

export default App;
