/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

/*
ICON SVG path parameters taken from bootstrap Person Circle 
Wikicommons 

*/

import React from "react";
import { t } from "@cmsgov/design-system";
import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 100 100",
};

export default function SHPublicHealth(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <circle cx="48.19" cy="8.159" r="8.159"></circle>
      <path d="M48.673,26.479c2.883,0,4.312-1.017,4.471-8.462l-8.939-0.004C44.362,25.462,45.792,26.479,48.673,26.479z"></path>
      <path d="M33.289,59.3c-0.153,0-0.308-0.008-0.461-0.022v10.434c0,2.943,2.067,4.109,3.72,3.516l0.002-15.111  C35.604,58.896,34.43,59.3,33.289,59.3z"></path>
      <path d="M28.071,53.648V43.082L13.56,43.073c-5.195,0.001-8.449,4.712-8.449,10.589v16.049  c0,4.869,5.785,4.869,5.785,0V54.332h1.841L12.73,93.488c0,2.524,1.843,4.574,4.112,4.574c2.274,0,4.115-2.05,4.115-4.574  l0.004-21.374h1.832L22.78,93.488c0,2.524,1.842,4.574,4.113,4.574c2.27,0,4.11-2.05,4.11-4.574l0.017-34.731  C29.375,57.938,28.071,56.204,28.071,53.648z"></path>
      <path d="M95.812,49.964L89.271,28.46c-0.806-2.419-2.967-6.808-8.291-6.808h-12.88c-0.217,0-0.422,0.016-0.627,0.031  c1.19,2.11,1.805,4.591,1.805,7.065v24.894c0.006,2.141-0.866,3.466-1.6,4.201c-0.953,0.956-2.228,1.483-3.587,1.483  c-1.123,0-2.278-0.396-3.213-1.162l-0.003,9.21h5.223l0.003,27.344c0,4.706,7.051,4.706,7.051,0l-0.003-27.524h2.862v27.562  c0,4.646,7.022,4.668,7.022,0V67.375h9.399L82.23,32.253h1.715l5.886,19.744C91.235,56.505,97.118,54.59,95.812,49.964z"></path>
      <path d="M21.839,26.479c-4.115,0-7.451,3.336-7.451,7.451s3.336,7.451,7.451,7.451c2.612,0,4.904-1.348,6.232-3.384  v-8.134C26.744,27.826,24.451,26.479,21.839,26.479z"></path>
      <path d="M60.896,53.648c0,2.452,1.599,3.679,3.196,3.679s3.193-1.227,3.187-3.679V28.749  c0-5.419-3.184-10.729-9.523-10.729l-2.349-0.001c-0.146,7.41-2.133,10.573-5.619,10.573v11.721c1.019,0.437,1.735,1.452,1.735,2.63  c0,1.588-1.268,2.89-2.85,2.89s-2.846-1.269-2.846-2.851c0-1.182,0.717-2.196,1.735-2.636V28.632c-3.496,0-5.481-3.177-5.619-10.62  l-2.476-0.001c-5.777,0.001-9.396,4.712-9.396,10.589v25.048c0,2.435,1.608,3.651,3.217,3.651c1.609,0,3.217-1.217,3.217-3.651  V29.27h2.048l-0.009,66.156c0,2.524,2.05,4.574,4.574,4.574c2.529,0,4.576-2.05,4.576-4.574l0.004-38.374h2.037l-0.014,38.374  c0,2.524,2.049,4.574,4.574,4.574c2.524,0,4.571-2.05,4.571-4.574l0.021-66.158h2.008V53.648z"></path>
      <circle fill="#000000" cx="74.576" cy="12.416" r="7.63"></circle>
    </SvgIcon>
  );
}
