
/*

Copyright 2023 - Current SUPARNA HEALTH AI LLC

Adopted from design.cms.gov
https://github.com/CMSgov/design-system/blob/main/packages/design-system/src/components/utilities/useClickOutsideHandler.ts

*/

import { RefObject, useEffect } from 'react';

type ClickOutsideEvent = MouseEvent | TouchEvent;

export function useSHAIClickOutsideHandler(
  insideRef: RefObject<HTMLElement>,
  handler: (e: ClickOutsideEvent) => any
) {
  
    useEffect(() => {
        function handleClickOutside(event: ClickOutsideEvent) {
          
        if ( !insideRef.current?.contains(event.target as HTMLElement)) {
         handler(event);
        }
     }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });
}

export default useSHAIClickOutsideHandler;