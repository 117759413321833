/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

import React from "react";

import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 1200 1200",
};

export default function SHHealthEquity(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <g fill="#00449e">
        <path d="m682.8 518.4h-158.4c-6 0-12 4.8008-12 12v33.602c0 6 4.8008 12 12 12h158.4c6 0 12-4.8008 12-12v-34.801c0.003906-6-5.9961-10.801-11.996-10.801z" />
        <path d="m682.8 620.4h-158.4c-6 0-12 4.8008-12 12v34.801c0 6 4.8008 12 12 12h158.4c6 0 12-4.8008 12-12v-34.801c0.003906-7.1992-5.9961-12-11.996-12z" />
        <path d="m380.4 710.4h-52.801l-9.6016-33.602c6-4.8008 12-9.6016 18-14.398 18 1.1992 34.801 3.6016 48 6 15.602 2.3984 28.801-9.6016 27.602-25.199-1.1992-19.199-2.3984-45.602-4.8008-75.602 9.6016-8.3984 14.398-21.602 14.398-39.602 0-20.398-4.8008-34.801-14.398-44.398-2.3984-1.1992-4.8008-3.6016-7.1992-4.8008-13.199-128.4-100.8-142.8-152.4-142.8-51.598 0-139.2 14.398-153.6 142.8-3.6016 1.1992-6 3.6016-7.1992 4.8008-9.6016 9.6016-14.398 24-14.398 44.398 0 18 4.8008 31.199 14.398 39.602-2.4023 30-3.6016 56.398-4.8008 75.598-1.1992 15.602 13.199 27.602 27.602 25.199 13.199-2.3984 30-4.8008 48-4.8008 6 4.8008 12 9.6016 18 14.398l-9.6016 33.602-51.602 0.003906c-46.801 0-84 37.199-84 84v52.801c0 12 9.6016 21.602 21.602 21.602h390c12 0 21.602-9.6016 21.602-21.602v-52.801c1.1953-48-37.203-85.203-82.805-85.203zm-252-144c-1.1992-8.3984-7.1992-14.398-14.398-14.398-9.6016-1.1992-16.801-1.1992-16.801-22.801 0-16.801 3.6016-22.801 6-25.199 0 0 1.1992-1.1992 2.3984-1.1992 1.1992 4.8008 4.8008 9.6016 10.801 9.6016 7.1992 1.1992 13.199-3.6016 14.398-10.801 4.8008-31.199 10.801-54 20.398-70.801l7.1992 31.199c1.1992 4.8008 6 8.3984 10.801 8.3984h152.4c4.8008 0 9.6016-3.6016 10.801-8.3984l7.1992-31.199c9.6016 16.801 15.602 39.602 20.398 70.801 1.1992 6 6 10.801 13.199 10.801h2.3984c4.8008-1.1992 9.6016-4.8008 10.801-9.6016 1.1992 0 2.3984 1.1992 2.3984 1.1992 2.3984 2.3984 6 8.3984 6 25.199 0 21.602-7.1992 22.801-16.801 22.801-8.3984 1.1992-14.398 7.1992-14.398 14.398-3.6016 33.602-24 63.602-62.398 90-33.602 22.801-79.199 22.801-112.8 0-34.789-26.398-56.391-57.598-59.992-90zm166.8 180-34.801 25.199c-8.3984 6-19.199 6-27.602 0l-34.801-25.199c-7.1992-6-10.801-15.602-8.3984-24l8.3984-32.398c15.602 6 31.199 9.6016 48 9.6016 16.801 0 33.602-3.6016 48-9.6016l8.3984 32.398c3.6055 8.4023 0.003907 18-7.1953 24z" />
        <path d="m222 522c0 12.594-10.207 22.801-22.801 22.801-12.59 0-22.801-10.207-22.801-22.801s10.211-22.801 22.801-22.801c12.594 0 22.801 10.207 22.801 22.801" />
        <path d="m318 522c0 12.594-10.207 22.801-22.801 22.801-12.59 0-22.801-10.207-22.801-22.801s10.211-22.801 22.801-22.801c12.594 0 22.801 10.207 22.801 22.801" />
        <path d="m295.2 576h-96c-4.8008 0-8.3984 4.8008-8.3984 9.6016 6 26.398 28.801 45.602 56.398 45.602 27.602 0 50.398-19.199 56.398-45.602 0.003906-4.8008-3.5977-9.6016-8.3984-9.6016z" />
        <path d="m1075.2 708h-45.602l-7.1992-26.398c55.199-13.199 62.398-48 68.398-105.6 0-3.6016 1.1992-6 1.1992-9.6016 13.199-2.3984 32.398-10.801 32.398-44.398 0-25.199-12-40.801-33.602-44.398 0-13.199 0-28.801-1.1992-46.801-4.8008-64.801-42-81.602-93.602-86.398h-2.3984c-45.602-3.6016-132 0-169.2-13.199-8.3984-2.3984-15.602 2.3984-10.801 12 2.3984 6 4.8008 12 6 16.801-2.3984-1.1992-4.8008-1.1992-6-2.3984-4.8008-1.1992-8.3984 2.3984-7.1992 7.1992 6 20.398 2.3984 87.602 0 112.8-21.602 3.6016-32.398 19.199-32.398 44.398 0 33.602 20.398 42 32.398 44.398 0 3.6016 1.1992 7.1992 1.1992 9.6016 6 56.398 13.199 91.199 68.398 105.6l-7.1914 26.395h-45.602c-52.801 0-94.801 42-94.801 94.801v44.398c0 12 9.6016 21.602 21.602 21.602h398.4c12 0 21.602-9.6016 21.602-21.602v-44.398c0-52.801-42-94.801-94.801-94.801zm-242.4-134.4c0-6-1.1992-12-2.3984-16.801-1.1992-8.3984-7.1992-14.398-14.398-14.398-12-1.1992-16.801-3.6016-16.801-19.199 0-16.801 6-19.199 18-19.199 3.6016 0 4.8008 1.1992 6 3.6016 2.3984 7.1992 9.6016 10.801 16.801 8.3984 7.1992-2.3984 10.801-9.6016 8.3984-16.801-2.3984-6-6-10.801-10.801-14.398 2.3984-20.398 4.8008-37.199 9.6016-51.602 16.801 10.801 48 18 102 18s85.199-7.1992 102-18c4.8008 14.398 7.1992 32.398 9.6016 51.602-4.8008 3.6016-8.3984 8.3984-10.801 14.398-2.3984 7.1992 1.1992 14.398 7.1992 16.801 7.1992 2.3984 14.398-1.1992 16.801-7.1992 1.1992-2.3984 2.3984-3.6016 6-3.6016 12 0 18 2.3984 18 19.199 0 15.602-4.8008 18-16.801 19.199-8.3984 1.1992-14.398 7.1992-14.398 14.398-1.1992 6-1.1992 12-2.3984 18-7.1992 66-9.6016 88.801-116.4 88.801-105.61-2.4023-108.01-25.199-115.21-91.199zm171.6 162c-34.801 18-75.602 18-110.4 0-3.6016-1.1992-4.8008-4.8008-3.6016-8.3984l10.801-40.801c13.199 1.1992 30 2.3984 48 2.3984s33.602-1.1992 48-2.3984l10.801 40.801c1.1992 3.5977-1.1992 7.1953-3.6016 8.3984z" />
        <path d="m924 516c0 12.594-10.207 22.801-22.801 22.801-12.59 0-22.801-10.207-22.801-22.801s10.211-22.801 22.801-22.801c12.594 0 22.801 10.207 22.801 22.801" />
        <path d="m1020 516c0 12.594-10.207 22.801-22.801 22.801-12.59 0-22.801-10.207-22.801-22.801s10.211-22.801 22.801-22.801c12.594 0 22.801 10.207 22.801 22.801" />
        <path d="m1004.4 577.2c1.1992-4.8008-2.3984-9.6016-8.3984-9.6016h-96c-4.8008 0-8.3984 4.8008-8.3984 9.6016 6 26.398 28.801 45.602 56.398 45.602 28.801 0 51.602-19.199 56.398-45.602z" />
      </g>
    </SvgIcon>
  );
}
