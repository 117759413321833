/*

Copyright 2023 - current SUPARNA HEALTH AI LLC

*/

import SHAIPublicHealth from "./../../images/shaipublichealth.png";

import "./PublicHealth.css";

const SHAIPublicHealthMainImage = () => {
  return (
    <img
      className="shai-dataplatform-image"
      src={SHAIPublicHealth}
      alt="Suparna Health Data Platform - Public Health"
    />
  );
};

export default SHAIPublicHealthMainImage;
