/*


Copyright 2023 - Present SUPARNA HEALTH AI LLC


*/

import { MenuIconThin } from "@cmsgov/design-system";

import { useNavigate } from "react-router-dom";

import './Header.css'
import SHAILogoImage from "./SHAILogoImage";

import SHGlobe from "../icons/SHLGlobe";
import SHPersonCircle from "../icons/SHPersonCircle";

import SHIconButtonLink from "../button/SHIconButtonLink";
import SHPrimaryMenu from "../menu/SHPrimaryMenu";

const SHPublicHeader = () => {
  const navigate = useNavigate()
  const SHNavToMainpage = () => {
    navigate("/")
  }
  return (
    <header className="ds-u-padding-top--2 ds-u-padding-bottom--1 ds-u-display--block ds-u-fill--primary-darkest">
      <div className="ds-u-display--flex ds-u-flex-direction--row ds-u-justify-content--between">
        <div
          className="ds-u-md-margin-left--7 ds-u-display--flex ds-u-flex-direction--row shai-logo-name-cursor"
          onClick={SHNavToMainpage}
        >
          <div className="ds-u-margin-right--2 ds-u-md-display--none">
            <MenuIconThin className="ds-c-icon-color--inverse ds-u-md-margin-right--1" />
          </div>
          <SHAILogoImage />
          <span className="ds-u-margin-left--2 ds-u-color--white ds-u-md-font-size--2xl ds-u-valign--middle">
            {" "}
            SUPARNA HEALTH AI
          </span>
        </div>
        <div className="ds-u-color--white  ds-u-md-margin-right--5">
          <ul className="ds-u-text-decoration--none ds-u-display--flex ds-u-flex-direction--row shai-menu-items ds-u-padding--0 ds-u-margin--0">
            <li className="ds-u-md-margin-right--2">
              {" "}
              <SHIconButtonLink label={"English (US)"}>
                {" "}
                <SHGlobe />{" "}
              </SHIconButtonLink>
            </li>
            <li>
              <SHIconButtonLink label={"Login"}>
                {" "}
                <SHPersonCircle />
              </SHIconButtonLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="ds-u-display--none ds-u-md-display--flex">
        <SHPrimaryMenu />
      </div>
    </header>
  );
};

export default SHPublicHeader;
