/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

import React from "react";

import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 1200 1200",
};

export default function SHMultipleHumanContacts(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <g fill="#00449e">
        <path d="m600 171.88c-33.746 0-61.215-27.465-61.215-61.215 0-33.746 27.465-61.215 61.215-61.215 33.746 0 61.215 27.465 61.215 61.215s-27.469 61.215-61.215 61.215zm0-82.438c-11.691 0-21.215 9.5195-21.215 21.215 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215s-9.5234-21.215-21.215-21.215z" />
        <path d="m674.79 296.31c-11.039 0-20-8.9453-20-20 0-30.215-24.586-54.785-54.785-54.785-30.215 0-54.785 24.574-54.785 54.785 0 11.055-8.9453 20-20 20-11.055 0-20-8.9453-20-20 0-52.266 42.52-94.785 94.785-94.785s94.785 42.52 94.785 94.785c0 11.051-8.9453 20-20 20z" />
        <path d="m600 1026.1c-33.746 0-61.215-27.465-61.215-61.215 0-33.762 27.465-61.227 61.215-61.227 33.746 0 61.215 27.465 61.215 61.227 0 33.746-27.469 61.215-61.215 61.215zm0-82.441c-11.691 0-21.215 9.5195-21.215 21.227 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215 0-11.707-9.5234-21.227-21.215-21.227z" />
        <path d="m674.79 1150.6c-11.039 0-20-8.9609-20-20 0-30.227-24.586-54.801-54.785-54.801-30.215 0-54.785 24.586-54.785 54.801 0 11.039-8.9453 20-20 20-11.055 0-20-8.9609-20-20 0-52.281 42.52-94.801 94.785-94.801s94.785 42.52 94.785 94.801c0 11.043-8.9453 20-20 20z" />
        <path d="m852.6 1026.1c-33.746 0-61.215-27.465-61.215-61.215 0-33.762 27.465-61.227 61.215-61.227 33.746 0 61.215 27.465 61.215 61.227-0.003906 33.746-27.457 61.215-61.215 61.215zm0-82.441c-11.691 0-21.215 9.5195-21.215 21.227 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215-0.003906-11.707-9.5234-21.227-21.215-21.227z" />
        <path d="m927.4 1150.6c-11.039 0-20-8.9609-20-20 0-30.227-24.586-54.801-54.785-54.801s-54.785 24.586-54.785 54.801c0 11.039-8.9609 20-20 20s-20-8.9609-20-20c0-52.281 42.52-94.801 94.785-94.801s94.785 42.52 94.785 94.801c0 11.043-8.957 20-20 20z" />
        <path d="m1105.2 1026.1c-33.746 0-61.215-27.465-61.215-61.215 0-33.762 27.465-61.227 61.215-61.227 33.746 0 61.215 27.465 61.215 61.227-0.015626 33.746-27.469 61.215-61.215 61.215zm0-82.441c-11.691 0-21.215 9.5195-21.215 21.227 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215-0.015626-11.707-9.5234-21.227-21.215-21.227z" />
        <path d="m1180 1150.6c-11.039 0-20-8.9609-20-20 0-30.227-24.586-54.801-54.785-54.801-30.199 0-54.785 24.586-54.785 54.801 0 11.039-8.9609 20-20 20-11.039 0-20-8.9609-20-20 0-52.281 42.52-94.801 94.785-94.801 52.266 0.003907 94.785 42.523 94.785 94.801 0 11.043-8.9609 20-20 20z" />
        <path d="m347.4 1026.1c-33.746 0-61.215-27.465-61.215-61.215 0-33.762 27.465-61.227 61.215-61.227 33.762 0 61.215 27.465 61.215 61.227 0 33.746-27.465 61.215-61.215 61.215zm0-82.441c-11.691 0-21.215 9.5195-21.215 21.227 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215 0-11.707-9.5195-21.227-21.215-21.227z" />
        <path d="m422.2 1150.6c-11.055 0-20-8.9609-20-20 0-30.227-24.586-54.801-54.801-54.801-30.215 0-54.785 24.586-54.785 54.801 0 11.039-8.9453 20-20 20-11.055 0-20-8.9609-20-20 0-52.281 42.52-94.801 94.785-94.801 52.266 0 94.801 42.52 94.801 94.801 0 11.043-8.9609 20-20 20z" />
        <path d="m94.785 1026.1c-33.746 0-61.215-27.465-61.215-61.215 0-33.762 27.465-61.227 61.215-61.227 33.762 0 61.215 27.465 61.215 61.227 0.011719 33.746-27.453 61.215-61.215 61.215zm0-82.441c-11.691 0-21.215 9.5195-21.215 21.227 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215 0.011719-11.707-9.5078-21.227-21.215-21.227z" />
        <path d="m169.59 1150.6c-11.055 0-20-8.9609-20-20 0-30.227-24.586-54.801-54.801-54.801-30.211 0.003907-54.785 24.574-54.785 54.801 0 11.039-8.9453 20-20 20s-20-8.957-20-20c0-52.281 42.52-94.801 94.785-94.801s94.801 42.52 94.801 94.801c0 11.043-8.9453 20-20 20z" />
        <path d="m600 601.28c-33.746 0-61.215-27.465-61.215-61.215 0-33.746 27.465-61.215 61.215-61.215 33.746 0 61.215 27.465 61.215 61.215 0 33.746-27.469 61.215-61.215 61.215zm0-82.414c-11.691 0-21.215 9.5195-21.215 21.215 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215 0-11.695-9.5234-21.215-21.215-21.215z" />
        <path d="m674.79 725.72c-11.039 0-20-8.9609-20-20 0-30.215-24.586-54.785-54.785-54.785-30.215 0-54.785 24.586-54.785 54.785 0 11.039-8.9453 20-20 20-11.055 0-20-8.9609-20-20 0-52.266 42.52-94.785 94.785-94.785s94.785 42.52 94.785 94.785c0 11.043-8.9453 20-20 20z" />
        <path d="m852.6 601.28c-33.746 0-61.215-27.465-61.215-61.215 0-33.746 27.465-61.215 61.215-61.215 33.746 0 61.215 27.465 61.215 61.215-0.003906 33.746-27.457 61.215-61.215 61.215zm0-82.414c-11.691 0-21.215 9.5195-21.215 21.215 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215-0.003906-11.695-9.5234-21.215-21.215-21.215z" />
        <path d="m927.4 725.72c-11.039 0-20-8.9609-20-20 0-30.215-24.586-54.785-54.785-54.785s-54.785 24.586-54.785 54.785c0 11.039-8.9609 20-20 20s-20-8.9609-20-20c0-52.266 42.52-94.785 94.785-94.785s94.785 42.52 94.785 94.785c0 11.043-8.957 20-20 20z" />
        <path d="m347.4 601.28c-33.746 0-61.215-27.465-61.215-61.215 0-33.746 27.465-61.215 61.215-61.215 33.762 0 61.215 27.465 61.215 61.215 0 33.746-27.465 61.215-61.215 61.215zm0-82.414c-11.691 0-21.215 9.5195-21.215 21.215 0 11.691 9.5195 21.215 21.215 21.215 11.691 0 21.215-9.5195 21.215-21.215 0-11.695-9.5195-21.215-21.215-21.215z" />
        <path d="m422.2 725.72c-11.055 0-20-8.9609-20-20 0-30.215-24.586-54.785-54.801-54.785-30.215 0-54.785 24.586-54.785 54.785 0 11.039-8.9453 20-20 20-11.055 0-20-8.9609-20-20 0-52.266 42.52-94.785 94.785-94.785 52.266 0 94.801 42.52 94.801 94.785 0 11.043-8.9609 20-20 20z" />
        <path d="m872.6 378.21c0-11.055-8.9609-20-20-20h-232.6v-70.426c0-11.055-8.9453-20-20-20s-20 8.9453-20 20v70.426h-232.6c-11.055 0-20 8.9453-20 20 0 0.49219 0.10547 0.96094 0.14844 1.4531-0.039063 0.49219-0.14844 0.96094-0.14844 1.4531v48.027c0 11.055 8.9453 20 20 20 11.055 0 20-8.9453 20-20v-30.934h212.6v28.027c0 11.055 8.9453 20 20 20s20-8.9453 20-20v-28.027h212.6v30.934c0 11.055 8.9609 20 20 20 11.039 0 20-8.9453 20-20v-48.027c0-0.49219-0.10547-0.96094-0.14844-1.4531 0.039063-0.48047 0.14844-0.94922 0.14844-1.4531z" />
        <path d="m1125.2 803.05c0-11.039-8.9609-20-20-20h-232.61v-67.508c0-11.039-8.9609-20-20-20-11.039 0-20 8.9609-20 20v67.508h-212.6v-70.426c0-11.039-8.9453-20-20-20s-20 8.9609-20 20v70.426h-212.6v-70.426c0-11.039-8.9453-20-20-20-11.055 0-20 8.9609-20 20v70.426h-232.61c-11.055 0-20 8.9609-20 20v48.027c0 11.039 8.9453 20 20 20 11.055 0 20-8.9609 20-20v-28.027h212.6v30.949c0 11.039 8.9453 20 20 20 11.055 0 20-8.9609 20-20v-30.965h212.61v28.027c0 11.039 8.9453 20 20 20s20-8.9609 20-20v-28.027h212.6v30.934c0 11.039 8.9609 20 20 20 11.039 0 20-8.9609 20-20v-30.934h212.6v30.934c0 11.039 8.9609 20 20 20s20-8.9609 20-20v-48.027c0-0.49219-0.10547-0.97266-0.14844-1.4531 0.039062-0.48047 0.16016-0.94531 0.16016-1.4375z" />
      </g>
    </SvgIcon>
  );
}
