/*


Copyright 2023 - Presemt SUPARNA HEALTH AI LLC

*/

import React from "react";

import { SvgIcon, IconCommonProps } from "@cmsgov/design-system";

const defaultProps = {
  className: "",
  viewBox: "0 0 1200 1200",
};

export default function SHDiseasePathogen(
  props: IconCommonProps
): React.ReactElement {
  const iconCssClasses = `${props.className || ""}`;

  return (
    <SvgIcon title={""} {...defaultProps} {...props} className={iconCssClasses}>
      <g fill="#00449e">
        <path d="m600 0c-330.84 0-600 269.16-600 600s269.16 600 600 600 600-269.16 600-600-269.16-600-600-600zm0 1155.6c-306.33 0-555.55-249.23-555.55-555.55s249.23-555.55 555.55-555.55 555.55 249.23 555.55 555.55-249.23 555.55-555.55 555.55z" />
        <path d="m903.93 786.47c-4.1719 4.168-6.5117 9.8203-6.5117 15.711 0 5.8945 2.3398 11.547 6.5117 15.715 6.2539 6.25 9.7695 14.73 9.7695 23.57 0 8.8438-3.5156 17.324-9.7695 23.574-6.3516 6.0586-14.793 9.4375-23.57 9.4375s-17.215-3.3789-23.566-9.4375c-5.6328-5.5234-13.77-7.6445-21.379-5.5781-7.6094 2.0664-13.555 8.0117-15.621 15.625-2.0664 7.6094 0.054687 15.746 5.5781 21.379 14.57 14.652 34.375 22.902 55.039 22.93 20.668 0.027344 40.492-8.168 55.105-22.781s22.809-34.438 22.777-55.105c-0.027344-20.664-8.2773-40.469-22.93-55.039-4.168-4.1719-9.8203-6.5156-15.719-6.5156-5.8945 0-11.551 2.3438-15.715 6.5156z" />
        <path d="m524.8 325.2c0-20.629-8.1953-40.414-22.781-55-14.586-14.586-34.371-22.781-55-22.777-20.629 0-40.414 8.1992-55 22.785-5.6133 5.6133-7.8047 13.797-5.75 21.461 2.0547 7.668 8.043 13.66 15.711 15.715 7.668 2.0547 15.852-0.14062 21.465-5.7539 6.3516-6.0625 14.793-9.4414 23.574-9.4414 8.7812 0.003906 17.223 3.3906 23.57 9.4531 6.2539 6.25 9.7656 14.73 9.7656 23.57 0 8.8398-3.5117 17.316-9.7656 23.566-4.2344 4.1523-6.6367 9.8242-6.6641 15.758-0.03125 5.9297 2.3125 11.629 6.5078 15.824 4.1953 4.1914 9.8906 6.5352 15.82 6.5078 5.9336-0.03125 11.605-2.4297 15.758-6.6641 14.641-14.555 22.844-34.363 22.789-55.004z" />
        <path d="m394.45 805.55c5.9336 0.0625 11.645-2.2539 15.863-6.4258 4.2148-4.1758 6.5898-9.8633 6.5898-15.797 0-5.9336-2.375-11.621-6.5898-15.793-4.2188-4.1758-9.9297-6.4883-15.863-6.4297-27.789 0-53.465 14.824-67.355 38.891-13.895 24.062-13.895 53.711 0 77.777 13.891 24.062 39.566 38.887 67.355 38.887 5.9336 0.0625 11.645-2.2539 15.863-6.4258 4.2148-4.1758 6.5898-9.8633 6.5898-15.797s-2.375-11.621-6.5898-15.793c-4.2188-4.1758-9.9297-6.4883-15.863-6.4297-11.91 0-22.914-6.3516-28.867-16.664-5.9531-10.312-5.9531-23.02 0-33.332 5.9531-10.316 16.957-16.668 28.867-16.668z" />
        <path d="m886.8 516.95c7.8594-0.039063 15.117-4.2305 19.078-11.02 3.9648-6.7891 4.0469-15.168 0.21484-22.035-3.8281-6.8633-11-11.195-18.859-11.391-0.54297 0-53.754-1.5859-79.09-33.047-13.055-16.191-16.723-37.848-11.219-66.203 8.8438-45.648 2.2461-82.93-19.629-110.84-32.359-41.289-85.871-44.402-88.125-44.512l-0.003907 0.003906c-7.8867-0.35156-15.371 3.5078-19.656 10.141-4.2852 6.6328-4.7305 15.039-1.1641 22.086 3.5625 7.0469 10.598 11.676 18.477 12.152 0.35938 0.019532 36.066 2.4844 55.695 27.812 13.402 17.293 17.012 42.422 10.754 74.695-8.0508 41.547-1.1719 76.129 20.453 102.8 38.551 47.559 109.67 49.297 112.67 49.352z" />
        <path d="m424.63 535.57c-5.8828-0.26562-11.629 1.8008-15.992 5.7539-4.3633 3.9492-6.9883 9.4648-7.3047 15.34-0.019532 0.35547-2.4531 35.688-27.551 55.359-17.273 13.562-42.523 17.285-74.945 10.973-59.203-11.449-106 8.4375-131.83 56.074-2.8125 5.1797-3.4492 11.266-1.7734 16.914 1.6797 5.6523 5.5312 10.402 10.715 13.207 3.2422 1.7695 6.875 2.6953 10.57 2.6914 4.0039 0.003906 7.9336-1.0742 11.379-3.1211 3.4414-2.0469 6.2656-4.9883 8.1758-8.5078 16.504-30.414 44.086-41.453 84.301-33.617 45.672 8.8203 82.941 2.2344 110.83-19.629 41.285-32.367 44.398-85.871 44.508-88.125 0.29688-5.8867-1.7539-11.652-5.7109-16.027-3.9531-4.3711-9.4844-6.9922-15.371-7.2852z" />
        <path d="m644.45 611.11c-17.676 0.019531-34.621 7.0469-47.121 19.547-12.496 12.496-19.527 29.441-19.547 47.117v88.891c0 23.816 12.707 45.824 33.336 57.734 20.625 11.906 46.039 11.906 66.664 0 20.625-11.91 33.332-33.918 33.332-57.734v-88.891c-0.019531-17.676-7.0469-34.621-19.547-47.117-12.496-12.5-29.441-19.527-47.117-19.547zm22.223 155.55h-0.003907c0.0625 5.9336-2.25 11.645-6.4258 15.859-4.1758 4.2188-9.8633 6.5898-15.793 6.5898-5.9336 0-11.621-2.3711-15.797-6.5898-4.1758-4.2148-6.4883-9.9258-6.4258-15.859v-88.891c0.078125-7.8867 4.332-15.137 11.176-19.055 6.8398-3.9219 15.25-3.9219 22.09 0 6.8438 3.918 11.098 11.168 11.176 19.055z" />
        <path d="m171.54 503.83c16.523 6.2617 34.855 5.7188 50.98-1.5078 16.129-7.2227 28.734-20.547 35.059-37.047l31.641-83.051c8.2188-22.191 4.0547-47.074-10.938-65.383-14.988-18.309-38.562-27.297-61.938-23.621-23.375 3.6797-43.051 19.473-51.695 41.5l-31.652 83.051c-6.2812 16.523-5.7539 34.863 1.4688 50.996 7.2266 16.133 20.555 28.742 37.062 35.062zm2.9844-70.215 31.66-83.062v-0.003907c2.875-7.3477 9.4336-12.625 17.23-13.852 7.7969-1.2305 15.66 1.7656 20.656 7.875 4.9961 6.1094 6.3789 14.414 3.6289 21.809l-31.66 83.062c-2.875 7.3516-9.4336 12.625-17.23 13.855s-15.66-1.7695-20.656-7.8789c-5-6.1055-6.3789-14.41-3.6289-21.809z" />
        <path d="m665.28 964.65-83.051-31.641c-16.523-6.2852-34.863-5.7539-50.996 1.4688-16.133 7.2227-28.742 20.551-35.062 37.062-6.2578 16.523-5.7148 34.855 1.5078 50.977 7.2266 16.125 20.547 28.73 37.047 35.059l83.051 31.641c22.254 8.4844 47.344 4.4531 65.82-10.574 18.477-15.031 27.527-38.773 23.75-62.289-3.7773-23.516-19.812-43.23-42.066-51.715zm-2.9844 70.203c-2.125 5.4883-6.3281 9.9141-11.699 12.316-5.3672 2.4062-11.469 2.5977-16.977 0.53125l-83.062-31.664c-7.3359-2.8828-12.594-9.4375-13.816-17.227-1.2227-7.7852 1.7695-15.637 7.8672-20.633 6.0977-4.9961 14.383-6.3867 21.777-3.6523l83.062 31.664c5.4844 2.1211 9.9102 6.3242 12.316 11.691 2.4062 5.3672 2.5977 11.465 0.53125 16.973z" />
        <path d="m1061.4 549.08c-11.648-13.293-28.098-21.426-45.734-22.605-17.637-1.1836-35.023 4.6797-48.34 16.301l-66.926 58.531c-17.797 15.715-25.891 39.707-21.25 62.992 4.6367 23.285 21.309 42.344 43.766 50.043 22.457 7.6992 47.316 2.875 65.262-12.668l66.93-58.531c13.285-11.652 21.406-28.098 22.586-45.73 1.1797-17.633-4.6797-35.012-16.293-48.332zm-35.566 60.609-66.906 58.516c-4.4297 3.9258-10.242 5.9219-16.148 5.5469s-11.422-3.0938-15.316-7.5469c-3.8984-4.457-5.8555-10.281-5.4453-16.184 0.41406-5.9062 3.1641-11.398 7.6445-15.27l66.918-58.52c5.9922-5.1055 14.238-6.6602 21.68-4.0898 7.4375 2.5742 12.965 8.8906 14.52 16.609 1.5586 7.7188-1.0859 15.684-6.9453 20.938z" />
        <path d="m607.86 528.17c5.6328 5.5234 13.77 7.6445 21.379 5.5781s13.555-8.0117 15.621-15.625c2.0664-7.6094-0.054688-15.746-5.5781-21.379l-47.137-47.137 0.003906 0.003906c-4.1523-4.2344-9.8281-6.6367-15.758-6.6641-5.9336-0.03125-11.629 2.3125-15.824 6.5078-4.1914 4.1953-6.5352 9.8906-6.5078 15.824 0.03125 5.9297 2.4297 11.605 6.668 15.758z" />
        <path d="m507.86 183.72c4.1602 4.2031 9.8242 6.5703 15.734 6.5859 5.9141 0.015625 11.586-2.3281 15.766-6.5078 4.1836-4.1797 6.5234-9.8555 6.5117-15.766-0.015626-5.9141-2.3867-11.574-6.5859-15.734l-47.137-47.137c-5.6328-5.5195-13.77-7.6445-21.379-5.5781-7.6133 2.0703-13.559 8.0156-15.625 15.625-2.0625 7.6094 0.058594 15.746 5.5781 21.379z" />
        <path d="m963.41 383.72c5.6133 5.6133 13.793 7.8047 21.461 5.75 7.668-2.0547 13.656-8.043 15.711-15.711 2.0547-7.668-0.13672-15.848-5.75-21.461l-47.137-47.145c-5.6289-5.5195-13.766-7.6445-21.379-5.5781-7.6094 2.0664-13.555 8.0117-15.621 15.621-2.0664 7.6133 0.054687 15.746 5.5742 21.379z" />
      </g>
    </SvgIcon>
  );
}
